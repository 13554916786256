<template>
    <div class="login-containers">
        <div class="login-weaper">

            <div class="left">
<!--                <img src="../assets/images/login/left@2x.png"/>-->
                <div class="title">
                    用心承载教育
                </div>
                <div class="desc">
                    学历提升、线上教育、职业培训、技能鉴定
                </div>
            </div>
            <div class="right">
                <div class="title">欢迎使用皓翰在线培训管理平台</div>
                <el-form class="login-form"
                         status-icon
                         :rules="loginRules"
                         ref="loginForm"
                         :model="loginForm"
                         label-width="0">
                    <el-form-item prop="username">
                        <el-input
                            v-model="loginForm.username"
                            auto-complete="off"
                            placeholder="请输入用户名">
                            <img src="../assets/images/login/user@2x.png" alt="" slot="prefix">
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input
                            v-model="loginForm.password"
                            :type="passwordType"
                            auto-complete="off"
                            placeholder="请输入密码">
                            <i class="el-icon-view el-input__icon"
                               slot="suffix"
                               @click="showPassword"></i>
                            <img src="../assets/images/login/pwd@2x.png" alt="" slot="prefix">
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="imgCode">
                        <el-row :span="24">
                            <el-col :span="18">
                                <el-input
                                    :maxlength="code.len"
                                    v-model="loginForm.imgCode"
                                    auto-complete="off"
                                    placeholder="请输入验证码">
                                    <img src="../assets/images/login/pwd@2x.png" alt="" slot="prefix">
                                </el-input>
                            </el-col>
                            <el-col :span="6">
                                <div class="login-code">
                                    <span v-if="code.type == 'text'" class="login-code-img" @click="refreshCode" >{{ code.value }}</span>
                                    <img v-else :src="code.src" class="login-code-img" @click="refreshCode" />
                                </div>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <p class="no_password" @click="forgetDialog=true">忘记密码</p>
                    <el-form-item>
                        <el-button type="primary"
                                   @click.native.prevent="handleLogin"
                                   class="login-submit">登录
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <el-dialog
            title="重新设置新密码"
            :visible.sync="pwdDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="30%">
            <el-row>
                <el-col :span="24" class="item_header"><span class="title">已超过90天未修改密码，请重新设置新密码</span></el-col>
            </el-row>
            <el-form :model="changeInfo" :rules="ruleValidate" ref="pwdForm" label-width="120px" label-suffix=":">
                <el-form-item label="新密码" prop="userPwd">
                    <el-input v-model="changeInfo.userPwd" show-password></el-input>
                </el-form-item>
                <el-form-item label="确认新密码" prop="userPwd2">
                    <el-input v-model="changeInfo.userPwd2" show-password></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="pwdDialog = false">关 闭</el-button>
                <el-button type="primary" @click="resetPwd('pwdForm')">确 认</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { Message } from 'element-ui'
import { encryptDes, vlaidPassword } from '@js/des'
import { loginByUsername } from "../assets/js/login";
export default {
    data() {
        return {
            loginForm: {
                username: "",
                password: "",
                imgCode: "",
                imgToken: ""
            },
            code: {
                src: "",
                value: "",
                len: 4,
                type: "image"
            },
            passwordType: "password",
            loginRules: {
                username: [
                    {
                        required: true,
                        message: "请输入用户名",
                        trigger: "blur"
                    }
                ],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur"
                    },
                    {
                        min: 1,
                        message: "密码长度最少为6位",
                        trigger: "blur"
                    }
                ],
                imgCode: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur"
                    }
                ]
            },
            forgetDialog: false,
            infoUrl: 'auth/admin/info/',
            pwdDialog: false,
            changeInfo: {
                userId: '',
                userPwd: '',
                userPwd2: '',
            },
            ruleValidate: {
                userPwd: [
                    { required: true, message: '请输入密码', trigger: 'blur' }
                ],
                userPwd2: [
                    { required: true, message: '请再次输入密码', trigger: 'blur' }
                ],
            },
        };
    },
    created() {
        this.appCookies.clearCookie();
        localStorage.clear(); //
        this.refreshCode();
    },
    mounted() {
        this.appCookies.clearCookie();
    },
    methods: {
        showPassword() {
            this.passwordType === ''
                ? (this.passwordType = 'password')
                : (this.passwordType = '')
        },
        refreshCode() {
            let imgToken = new Date().getTime();
            this.code.src = process.env.VUE_APP_API_HOST + 'notify/verify/genImage?imgToken=' + imgToken;
            this.loginForm.imgToken = imgToken;
            // this.loginForm.randomStr = randomLenNum(this.code.len, true)
            // this.code.type === 'text'
            //     ? (this.code.value = randomLenNum(this.code.len))
            //     : (this.code.src = `${this.codeUrl}?randomStr=${this.loginForm.randomStr}`)
        },
        handleLogin() {
            this.infoUrl = 'oauth/admin/info/';
            this.$refs.loginForm.validate(valid => {
                if (valid) {
                    // let loginType = this.$route.query.type;
                    let that = this;
                    loginByUsername(this.loginForm.username, encryptDes(this.loginForm.password),
                        this.loginForm.imgToken, this.loginForm.imgCode).then((res1) => {
                            console.log(res1);
                            if (res1.code === 1) {
                                this.appCookies.setCookieToten(res1.data);
                                // setCookieAccessToten(res.data.access_token);
                                that.apis.get(this.infoUrl + res1.data.access_token)
                                    .then((res) => {
                                        if (res.code === 1) {
                                            this.appCookies.setCookieUser(res.data);
                                            // setCookieUser(res.data);
                                            let redirect = this.$route.query.redirect;
                                            if (redirect) {
                                                that.$router.push({ path: redirect });
                                            } else {
                                                that.$router.push({ path: '/dashboard' });
                                            }
                                        } else if (res.code === 1001) {
                                            that.$message({
                                                message: '您超过90天未修改密码，请重置密码！', showClose: true, offset: 200, type: 'error'
                                            });
                                            that.changeInfo.userId = res.data.userId
                                            that.pwdDialog = true;
                                        } else {
                                            that.refreshCode()
                                            that.$message({
                                                message: res.msg,
                                                type: 'error',
                                                duration: 5 * 1000
                                            })
                                        }
                                    }).catch(err => {
                                    console.log(err);
                                });
                            } else {
                                this.refreshCode()
                                Message({
                                    message: res1.msg,
                                    type: 'error',
                                    duration: 5 * 1000
                                })
                            }
                        }).catch(err => {
                        console.log(err.message);
                    });
                }
            });
        },
        resetPwd: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {

                    if (!vlaidPassword(this.changeInfo.userPwd)) {
                        this.$message({
                            message: '您的密码强度太低（必须包含字母、数字和特称字符，至少8个字符，最多20个字符），请重新输入！', showClose: true, offset: 200, type: 'error'
                        });
                        this.changeInfo.userPwd = '';
                        return;
                    }
                    if (this.changeInfo.userPwd !== this.changeInfo.userPwd2) {
                        this.$message({
                            message: '两次输入的密码不一致',
                            showClose: true, offset: 50, type: 'error'
                        });
                        return;
                    }
                    // if (!this.vlaidPassword(this.changeInfo.userPwd)) {
                    //     this.$message({
                    //         message: '您的密码强度太低（至少8位且必须包含大写字母、小写字符和数字），请重新输入！', showClose: true, offset: 200, type: 'error'
                    //     });
                    //     this.changeInfo.userPwd = '';
                    //     return;
                    // }

                    let url = "system/admin/resetPwd";
                    let formData = {
                        userPwd: encryptDes(this.changeInfo.userPwd),
                        // userPwd: this.changeInfo.userPwd,
                        userId: this.changeInfo.userId,
                        modifyBy: this.appCookies.getCookieUID(),
                        modifyByname: this.appCookies.getCookieUNAME()
                    };
                    // 保存数据
                    this.apis.postForm(url, formData)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '设置成功，请重新登录',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.$router.push({ path: '/' });
                                this.pwdDialog = false;
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            });
        },
    }
}
</script>

<style scoped lang="scss">
.login-containers {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background: url("../assets/images/login/bg@2x.png") no-repeat;
    background-size: 100% 100%;
    animation: animate-cloud 20s linear infinite;
}

.login-weaper {
    width: 1100px;
    height: 663px;
    background: #FFFFFF;
    border-radius: 20px;
    margin: 0 auto;
    box-shadow: 0px 6px 114px 0px rgba(151, 151, 151, 0.78);
    //padding: 120px 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .left {
        height: 100%;
        width: 50%;
        background: url("../assets/images/login/left@2x.png") no-repeat;
        background-size: 100% 100%;
        padding: 60px 70px;
        img {
            height: 100%;
            width: 100%;
        }
        .title {
            font-size: 48px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 80px;
        }
        .desc {
            font-size: 20px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 32px;
        }
    }

    .right {
        height: 100%;
        width: 50%;
        padding: 100px 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .title {
            font-size: 32px;
            font-weight: 400;
            color: #333333;
            line-height: 120px;
        }

        .no_password {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            text-align: right;
            margin: 20px;
            cursor: pointer;
        }

        /deep/ .login-form {
            .el-form-item {
                margin-bottom: 30px;

                .el-input {
                    input {
                        padding-bottom: 10px;
                        text-indent: 5px;
                        background: transparent;
                        border: none;
                        border-radius: 0;
                        color: #333;
                        border-bottom: 1px solid rgb(235, 237, 242);
                    }

                    .el-input__prefix {
                        display: flex;
                        align-items: center;
                        padding-bottom: 10px;

                        img {
                            width: 16px;
                            height: 18px;
                        }
                    }
                }

                .el-button {
                    border: none;
                    width: 100%;
                    height: 50px;
                    background: #4F93FE;
                    //border: 1px solid #1A72FE;
                    border-radius: 30px;
                    font-size: 18px;
                    font-weight: 400;
                    color: #FFFFFF;
                }

                .login-code {
                    width: 80px;
                    height: 38px;

                    img {
                        width: 80px;
                        height: 38px;
                        border-radius: 2px;
                    }
                }
            }
        }

        .btn {
            width: 460px;
            height: 60px;
            background: #4F93FE;
            //border: 1px solid #1A72FE;
            border-radius: 30px;
            font-size: 28px;
            color: #FFFFFF;
            line-height: 60px;
            text-align: center;
            cursor: pointer;
        }
    }
}
</style>
