<template>
    <div class="ebook-home">
        <div class="header">
            <div class="header-container">
                <div class="left">
                    <div class="logo">
                        <img src="../../../assets/logonewtm2.png" alt="">
                    </div>
                    <div class="items">
                        <span class="item">皓翰在线培训管理平台</span>
                    </div>
                </div>
                <div class="right">
                    <el-input placeholder="请输入内容"  class="input-with-select">
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </div>
            </div>
        </div>
        <div class="content-container">
            <div class="top">
                <p class="title">使用说明v1.0</p>
            </div>
            <div class="book-index">
                <el-link v-for="(item, idx) in ebookList" :key="idx" :href="'/ebook/detail?ebookId=' + item.ebookId" :underline="false">
                    <div class="item" >
                        <span class="title">{{item.ebookName}}</span>
                        <p class="dot"></p>
                        <span class="time">{{ item.updateTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                    </div>
                </el-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            queryParams: {
                userId: this.appCookies.getCookieUID(),
                pageNumber: 1,
                pageSize: 10,
                totalRecord: 0,
                typeCode: '',
                keyword: '',
                delState: '0'
            },
            ebookList: [],
        }
    },
    mounted() {
        this.initTable();
    },
    methods: {
        initTable: function () {
            this.tableLoading = true;
            this.apis.postForm('system/ebook/info/query', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.ebookList = res.data;
                        this.queryParams.totalRecord = res.count;
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                this.tableLoading = false;
                console.log(err)
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .ebook-home {
        width: 100%;
        height: 100%;
        background-color: #FFFFFF;
        .header {
            z-index: 999;
            width: 100%;
            height: 60px;
            background: #fff;
            border-bottom: 1px solid #e8e9e8;
            .header-container {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                height: 100%;
                padding-left: 24px;
                padding-right: 24px;
                .left {
                    flex: 1;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    .logo {
                        height: 100%;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        margin-right: 20px;
                        img {
                            width: 40px;
                            height: 40px;
                        }
                    }
                    .items {
                        .item {
                            line-height: 56px;
                            padding: 0 14px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .content-container {
            display: flex;
            flex-direction: column;
            .top {
                height: 50px;
                vertical-align: center;
                text-align: center;
                margin-top: 40px;
                .title {
                    font-size: 24px;
                    margin: 8px 0 0 0;
                    text-align: center;
                    color: #262626;
                    font-weight: 500;
                }
            }
            .book-index {
                width: 1108px;
                padding-top: 16px;
                padding-bottom: 32px;
                margin: 0 auto;
                text-align: left;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .item {
                    width: 860px;
                    margin: 0 auto;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 36px;
                    .title {
                        padding: 0 24px 0 0;
                        font-size: 15px;
                        color: #585a5a;
                        background: #fff;
                    }
                    .time {
                        background: #fff;
                        padding-left: 32px;
                        color: #8a8f8d;
                    }
                    .dot {
                        flex: 1;
                        border-bottom: 1px dashed #e8e9e8;
                    }
                    //.dot:before {
                    //    content: " ";
                    //    width: 100%;
                    //    border-bottom: 1px dashed #e8e9e8;
                    //    position: absolute;
                    //    top: 50%;
                    //    right: 0;
                    //}
                }
            }
        }
    }
</style>
