<template>
    <div class="ebooks-container">
        <header class="navbar">
            <div class="chapter-header">
                <div class="link" @click="$router.go(-1)"><i class="el-icon-s-fold"></i></div>
                <div class="title">{{ bookInfo.ebookName }}</div>
                <div class="toolbar">
                    <div class="search-box">
                        <input type="text" placeholder="查询">
                    </div>
                </div>
            </div>
        </header>
        <div class="sidebar">
            <div class="toolbar">
                <span class="name">目录</span>
            </div>
            <div class="tree-container">
                <el-tree ref="chapterTree"
                         :data="chapterList"
                         :props="{label: 'chapterName', children: 'children'}"
                         highlight-current
                         :default-expand-all="true"
                         :expand-on-click-node="false"
                         @node-click="treeSelectChange">
                    <span class="custom-tree-node" slot-scope="{ node, data }">
            <!--                        <i v-if="!data.children" class="el-icon-document"></i>-->
                                    <!--                        <i v-else-if="data.children.length > 0 && node.expanded" class="el-icon-folder-opened"></i>-->
                                    <!--                        <i v-else-if="data.children.length > 0 && !node.expanded" class="el-icon-folder"></i>-->
                                    <!--                        <i v-else class="el-icon-document"></i>-->
                        <span :title='node.label || "-"' class="name">{{ data.chapterName }}</span>
                    </span>
                </el-tree>
            </div>
        </div>
        <div class="page">
            <div class="content">
                <markdown-it-vue class="md-body" :content="chapterContent.markdownContent" />
            </div>
        </div>
    </div>
</template>

<script>
import MarkdownItVue from 'markdown-it-vue';
import 'markdown-it-vue/dist/markdown-it-vue.css'
export default {
    components: {
        MarkdownItVue,
    },
    data() {
        return {
            ebookId: 0,
            chapterId: null,
            bookInfo: {},
            chapterList: [],
            chapterContent: {
                markdownContent: ''
            }
        }
    },
    mounted() {
        this.ebookId = this.$route.query.ebookId;
        this.chapterId = this.$route.query.chapterId;
        this.getEbookInfo();
        this.getChapter();
    },
    methods: {
        getEbookInfo: function () {
            this.apis.get('system/ebook/info/getById?ebookId=' + this.ebookId)
                .then((res) => {
                    if (res.code === 1) {
                        this.bookInfo = res.data;
                    } else {
                        this.bookInfo = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getChapter: function () {
            let that = this;
            let query = {
                ebookId: this.ebookId
            }
            this.apis.postForm('system/ebook/chapter/getTree', query)
                .then((res) => {
                    console.log(res)
                    if (res.code === 1) {
                        this.chapterList = res.data || [];
                        if (this.chapterList.length > 0) {
                            let data = null;
                            if (this.chapterId) {
                                let datas = this.chapterList.filter((p) => {
                                    return p.chapterId + '' === that.chapterId;
                                });
                                if (datas && datas.length >0) {
                                    data = datas[0];
                                }
                            } else {
                                data = this.chapterList[0]
                            }
                            this.getChapterContent(data.chapterId, data.chapterName)
                        }

                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        treeSelectChange: function (data) {
            // { categoryId: '', categoryName: '全部分类' }
            this.getChapterContent(data.chapterId, data.chapterName)
        },
        getChapterContent: function (chapterId, chapterName) {
            this.apis.get('system/ebook/chapter/content/getByChapterId?chapterId=' + chapterId)
                .then((res) => {
                    if (res.code === 1) {
                        this.chapterContent = res.data || { markdownContent: '' };
                        this.chapterContent.chapterName = chapterName
                    } else {
                        this.chapterContent = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.ebooks-container {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    box-sizing: border-box;
    .navbar {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 20;
        height: 60px;
        background-color: #fff;
        box-sizing: border-box;
        border-bottom: 1px solid #eaecef;
        padding: 12px 24px;
        .chapter-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            .link {
                width: 40px;
                height: 40px;
                background: #eee;
                border-radius: 20px;
                transition: background-color .3s;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
            .title {
                margin-left: 8px;
                font-weight: 500;
                color: #303030;
                font-size: 18px;
            }
            .toolbar {
                flex: 1;
                text-align: right;
                .search-box input {
                    cursor: text;
                    width: 10rem;
                    color: #4e6e8e;
                    display: inline-block;
                    border: 1px solid #cfd4db;
                    border-radius: 2rem;
                    font-size: .9rem;
                    line-height: 2rem;
                    padding: 0 0.5rem 0 2rem;
                    outline: none;
                    transition: all .2s ease;
                    background: #fff url(/assets/img/search.83621669.svg) 0.6rem 0.5rem no-repeat;
                    background-size: 1rem;
                }
            }
        }
    }
    .sidebar {
        font-size: 15px;
        background-color: #fff;
        width: 320px;
        position: fixed;
        z-index: 10;
        margin: 0;
        top: 60px;
        left: 0;
        bottom: 0;
        box-sizing: border-box;
        border-right: 1px solid #eaecef;
        overflow-y: auto;
        padding: 10px 20px;
        .toolbar {
            .name {
                font-weight: 500;
                color: #303030;
                font-size: 16px;
                width: 34px;
                text-align: center;
                border-bottom: #999999 2px solid;
                padding-bottom: 6px;
            }
        }
        .tree-container {
            padding: 15px 5px;
        }
    }
    .page {
        padding-left: 320px;
        padding-top: 60px;
        .content {
            width: 80%;
            margin: 0 auto;
            padding: 20px 10px;
            min-width: 780px;
        }
    }
}
    .dir {
        position: fixed;
        right: 0;
        top: 60px;
        width: 260px;
        height: calc(100vh - 70px);
        overflow: auto;
        border-left: #fafafa 1px solid;
        .dir-content {
            padding: 15px 15px;
        }
    }
    ::v-deep {
        .el-tree {
            background-color: #FFFFFF;
        }
        .el-tree-node__content {
            height: 40px;
            color: #3E4A54;
        }
    }
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
        .name {
            font-size: 15px;
            line-height: 30px;
            margin-left: 4px;
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .oper {
            position: absolute;
            right: 0;
            display: none;
            text-align: right;
            background: #FFFFFF;
            .el-button + .el-button {
                margin-left: 0px;
            }
        }
    }
</style>
