import request from './request'
import qs from "qs";

export const loginByUsername = (username, password, imgToken, imgCode) => {
    let basicAuth = 'Basic ' + window.btoa(process.env.VUE_APP_CLIENT_ID)
    let data = {
        grant_type: 'password',
        user_type: 'ADMIN',
        username: username,
        password: password,
        imgCode: imgCode,
        imgToken: imgToken
    }
    const config = {
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
            Authorization: basicAuth
        },
        url: '/oauth/oauth/token',
        // useToken: false
    }
    config.data = qs.stringify(data)
    return request(config)
}
